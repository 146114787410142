<template>
  <v-card tile pa-0 class="elevation-0">
    <v-card-title>
      <span class="headline">{{addOrEdit}} Location Entity</span>
      <!-- {{entity}} -->
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form v-model="isValidForm" ref="modify_loc_entity_form">
        <v-container>
          <v-row no-gutters align="center">
            <v-col cols="12" sm="4" md="4">
              <p class="black--text">Name*</p>
            </v-col>
            <v-col cols="12" sm="8" md="8">
              <v-text-field
                v-model="entity.name"
                hint="Please provide name for the entity"
                solo
                clearable
                persistent-hint
                :counter="counter"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <p class="black--text">Description*</p>
            </v-col>
            <v-col cols="12" sm="8" md="8">
              <v-text-field
                v-model="entity.description"
                hint="Please provide description for the entity"
                solo
                clearable
                persistent-hint
                :counter="counter"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <p class="black--text">Entity Type*</p>
            </v-col>
            <v-col cols="12" sm="8" md="8">
              <v-select
                v-model="entity.loc_entity_type_idx"
                solo
                chips
                :items="locEntityTypes"
                item-text="loc_entity_type_name"
                item-value="id"
                label="Entity Type"
                required
              ></v-select>
            </v-col>
            
            <v-col cols="12" sm="4" md="4">
              <p class="black--text">Latitude*</p>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="pr-1">
              <v-text-field
                v-model="entity.latitude"
                hint="Please provide latitude"
                solo
                clearable
                persistent-hint
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <p class="black--text">Longitude*</p>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="pr-1">
              <v-text-field
                v-model="entity.longitude"
                hint="Please provide longitude"
                solo
                clearable
                persistent-hint
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2" md="2">
              <v-dialog v-model="mapDialog" width="1200">
                <template v-slot:activator="{ on }">
                  <!-- <v-btn color="red lighten-2" dark v-on="on">Click Me</v-btn> -->
                  <v-icon v-on="on">my_location</v-icon>
                </template>
                <v-card>
                  <v-card-text class="pa-0">
                    <GmapMap
                      ref="testmap"
                      :center="{lat:10, lng:10}"
                      :zoom="zoom"
                      style="width:100%;height: 450px"
                      @click="onMapClick"
                    >
                      <gmap-marker
                        :icon="require('@/assets/City-Marker.svg')"
                        :position="marker.position"
                      ></gmap-marker>
                    </GmapMap>
                  </v-card-text>
                  <v-card-actions>
                    <div class="flex-grow-1"></div>
                    <v-btn color="primary" text @click="onAddLocation">Add Location</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <small>*indicates required field</small>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <div class="flex-grow-1"></div>
      <v-btn color="blue darken-1" text @click="onModifyLocEntityClose">Close</v-btn>
      <v-btn
        color="blue darken-1"
        text
        @click="addOrEdit == 'Edit' ? onEditLocEntitySave() : onAddLocEntitySave()"
      >Save</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { EventBus } from "@/lib/EventBus";
import Vue from "vue";
// import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'
import * as VueGoogleMaps from "vue2-google-maps";
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyCVwaxhZM4UtfzzyxZbRtqIo8bjz-tlljE",
    v: "3.31"
  }
});
export default {
  components: {},
  props: {
    entity: {
      type: Object,
      default: function() {
        return {
          parentId: null,
          id:"",
          name: "",
          description: "",
          loc_entity_type_idx: "",
          children: [],
          latitude:0,
          longitude:0,
          meters:[]
        };
      }
    },
    addOrEdit: {
      type: String,
      default: function() {
        return "Add sub";
      }
    }
  },
  data() {
    return {
      mapDialog: false,
      zoom: 3,
      isValidForm: true,
      counter: 0,
      marker: {
        position:{
          lat: Number(this.$props.entity.latitude),
          lng: Number(this.$props.entity.longitude)
        }
      },
      formTitle: this.$props.addOrEdit
    };
  },
  computed: {
    locEntityTypes() {
      return this.$store.getters["MDMStore/getterLocEntityTypes"];
    }
  },
  mounted() {
    // this.entityObj = Object.assign({},this.$props.entity)
  },
  methods: {
    checkNewOrEditLocEntity(){
      if(this.$router.currentRoute.fullPath =="/edit/loc_entity"){
        return "edited-"
      }else{
        return ""
      }
    },
    onAddLocEntitySave() {
       EventBus.$emit(this.checkNewOrEditLocEntity()+"add-loc-entity-save", this.entity);
      
    },
    onEditLocEntitySave() {
      EventBus.$emit(this.checkNewOrEditLocEntity()+"edit-loc-entity-save", this.entity);
    },
    onModifyLocEntityClose() {
      EventBus.$emit(this.checkNewOrEditLocEntity()+"add-loc-entity-close");
    },
    onMapClick(event) {
       this.marker ={};
       var marker = {position:{
         lat: Number(event.latLng.lat().toFixed(3)),
         lng: Number(event.latLng.lng().toFixed(3))
       }};
       Object.assign(this.marker, marker);
    },
    onAddLocation(){
      this.entity.latitude = this.marker.position.lat;
      this.entity.longitude =  this.marker.position.lng;
      this.mapDialog = false;
    }
  }
};
</script>

<style>
</style>