var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","pa-0":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-card-title',{staticClass:"secondary"},[_c('span',[_vm._v(_vm._s(_vm.orgDetails.hasOwnProperty("name") ? _vm.orgDetails.name : ""))]),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2 elevation-1",attrs:{"fab":"","dark":"","x-small":"","color":"primary"},on:{"click":function($event){_vm.onModifyLocEntity({id:_vm.generate_UUID(),parentId:null, children:[]}, 'Add')}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("add")])],1)]}}])},[_c('span',[_vm._v("Add location entity")])])],1),_c('v-card',{staticClass:"elevation-0",attrs:{"tile":""}},[_c('v-card-text',[_c('v-treeview',{attrs:{"items":_vm.locEntityTree,"item-key":"name"},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
var open = ref.open;
return [_c('v-icon',[_vm._v("label")])]}},{key:"append",fn:function(ref){
var item = ref.item;
var open = ref.open;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2 elevation-1",attrs:{"fab":"","dark":"","x-small":"","color":"primary"},on:{"click":function($event){_vm.onModifyLocEntity({id:_vm.generate_UUID(),parentId:item.id , children:[]}, 'Add sub')}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("add")])],1)]}}],null,true)},[_c('span',[_vm._v("Add sub location entity")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2 elevation-1",attrs:{"fab":"","dark":"","x-small":"","color":"primary"},on:{"click":function($event){return _vm.onModifyLocEntity(item, 'Edit')}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("edit")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit location entity")])])]}}]),model:{value:(_vm.locEntityTreeModel),callback:function ($$v) {_vm.locEntityTreeModel=$$v},expression:"locEntityTreeModel"}})],1)],1)],1),(_vm.showAddOrEditview)?_c('v-divider',{attrs:{"vertical":""}}):_vm._e(),(_vm.showAddOrEditview)?_c('v-col',{attrs:{"cols":"6","md":"6"}},[_c('v-card',{staticClass:"elevation-0",attrs:{"tile":""}},[_c('v-card-text',{staticClass:"pa-0"},[_c('modify-loc-entity',{attrs:{"entity":_vm.selectedEntity,"addOrEdit":_vm.modifyType}})],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }