<template>
  <v-container fluid pa-0>
    <v-row no-gutters class="pa-2">
      <v-col cols="12" md="12">
        <v-stepper v-model="e1" class="elevation-0">
          <v-stepper-header>
            <v-stepper-step :complete="e1 > 1" step="1">Setup Location Entity</v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="e1 > 2" step="2">Associate Meters</v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1" class="pa-0">
              <v-card tile>
                <v-card-text>
                  <!-- <physical-entity-list class="mb-2"></physical-entity-list> -->
                  <create-loc-entity-tree :addoredit="addOrEdit"></create-loc-entity-tree>

                  <!-- <v-dialog v-model="addPhysicalEntityDialog" persistent max-width="600px">
                    <template v-slot:activator="{ on }">
                      <v-btn color="primary" dark v-on="on">Add Physical Entity</v-btn>
                    </template>
                    <add-physical-entity></add-physical-entity>
                  </v-dialog>-->
                </v-card-text>
                <v-card-actions align="end" justify="end">
                  <v-spacer></v-spacer>
                  <v-btn color="primary" @click="onLocEntityTreeSave">Continue</v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>

            <v-stepper-content step="2" class="pa-0">
              <v-card tile>
                <v-card-text>
                  <link-loc-entity-meter :locentitytree="locEntityTree" :meters="meters"></link-loc-entity-meter>
                  <!-- <physical-entity-list class="mb-2"></physical-entity-list> -->
                  <!-- <loc-entity-tree></loc-entity-tree> -->

                  <!-- <v-dialog v-model="addPhysicalEntityDialog" persistent max-width="600px">
                    <template v-slot:activator="{ on }">
                      <v-btn color="primary" dark v-on="on">Add Physical Entity</v-btn>
                    </template>
                    <add-physical-entity></add-physical-entity>
                  </v-dialog>-->
                </v-card-text>
                <v-card-actions align="end" justify="end">
                  <v-spacer></v-spacer>
                  <v-btn color="primary" @click="onLinkLocationEntityMetersSave">Continue</v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>

          </v-stepper-items>
        </v-stepper>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CreateLocEntityTree from "../components/newUser/setupLocEntity/CreateLocEntityTree";
import LinkLocEntityMeter from "../components/newUser/associateLocEntityMeter/LinkLocEntityMeter";

import { EventBus } from "@/lib/EventBus";

export default {
  components: {
    CreateLocEntityTree,
    LinkLocEntityMeter
  },
  data() {
    return {
      e1: 1,
      locEntityTree: [],
      meters: [],
      addOrEdit:""
    };
  },
  mounted() {
    this.addOrEdit = this.$attrs.addOrEdit;
    EventBus.$on("on-loc-entity-saved", () => {
      this.$store.dispatch("MDMStore/getLocEntityTree").then(tree => {
        this.locEntityTree = tree;
        this.$store
          .dispatch({
            type: "MDMStore/getMeters",
            orgId: localStorage.getItem("orgId")
          })
          .then(meterGrps => {
            this.meters = meterGrps.flat();
            this.e1 = 2;
          });
      });
    });
    EventBus.$on("on-loc-entity-meter-saved", () => {
      this.$store.dispatch({
        type: "MDMStore/getOrgDetails",
        router: this.$router
      });
      this.$router.push({
        path: "/"
      });
    });
  },
  beforeCreate() {
  },
  created() {
  },
  methods: {
    onLocEntityTreeSave() {
      EventBus.$emit("loc-entity-tree-save");
    },
    onLinkLocationEntityMetersSave() {
      EventBus.$emit("loc-entity-meter-save");
    }
  }
};
</script>
