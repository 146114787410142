<template>
  <v-container fluid pa-0>
    <v-row no-gutters>
      <v-col cols="4" md="4">
        <v-card tile class="elevation-0">
          <v-card-title class="secondary">Location Entity</v-card-title>
          <v-card-text>
            <v-treeview
              :items="locentitytree"
              :active.sync="selectedEntity"
              open-on-click
              activatable
              return-object
            >
              <template v-slot:label="{ item, active , leaf}">
                <div @click="onLocEntityClick(leaf, active, item)">{{item.name}}</div>
              </template>
            </v-treeview>
          </v-card-text>
        </v-card>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col >
        <v-card tile class="elevation-0">
          <v-card-title class="secondary">Meters</v-card-title>
          <v-card-text class="pa-0">
            <v-chip-group multiple column active-class="primary--text">
              <v-chip
                label
                color="secondary"
                v-for="(meter) in meters"
                :key="meter.id"
                :disabled="selectedEntity.length == 0"
                @click="onMeterClick(meter)"
              >{{ meter.name }}</v-chip>
            </v-chip-group>
          </v-card-text>
        </v-card>
        <v-divider v-if="selectedEntity.length != 0"></v-divider>
        <v-card tile class="elevation-0">
          <v-card-title
            v-if="selectedEntity.length != 0"
            class="secondary"
          >{{selectedEntity[0].name }} Meters</v-card-title>
          <v-card-text class="pa-0">
            <v-chip-group column text-color="white">
              <v-chip
                color="primary"
                label
                v-for="meter in selectedEntityMeters"
                :key="'selected'+meter.id"
                close
                @click:close="onMeterClose(meter)"
              >{{ meter.name }}</v-chip>
            </v-chip-group>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import { EventBus } from "@/lib/EventBus";

export default {
  name: "link-loc-entity-meter",
  components: {},
  props: {
    locentitytree: {
      type: Array
    },
    meters: {
      type: Array
    }
  },
  data() {
    return {
      selectedEntity: [],
      entityMeterObjs: [],
      selectedEntityMeters: []
    };
  },
  mounted() {
    
    EventBus.$on("loc-entity-meter-save", () => {
      var entityMeters = this.entityMeterObjs.map((obj) => ({meter_idx: obj.meter.id, loc_entity_idx:obj.entity }));
      this.$store.dispatch({
          type: "MDMStore/saveLocEntityMeter",
          data: entityMeters
        }).then(result =>{
          if(result.data.hasOwnProperty("success") && result.data.success ){
            EventBus.$emit("on-loc-entity-meter-saved");
             this.$store.commit("MDMStore/SET_SNACKBAR_DETAILS", {text:"Meter associations saved successfully", status:"success"});
          }else{
            console.error("Err on loc-entity-meter-save");
            this.$store.commit("MDMStore/SET_SNACKBAR_DETAILS", {text:"Error in saving meter associations", status:"warning"});
          }
        })
    });
  },
  created() {},
  computed: {
  },
  methods: {
    onLocEntityClick(isLeaf, isActive, item) {
      if (isLeaf) {
        this.selectedEntityMeters = [];
        this.entityMeterObjs= [];
        this.entityMeterObjs = item.meters.map(m =>
            Object.assign({}, {meter:m ,loc_entity_idx: item.id })
          );
        // var entityMeters = this.entityMeterObjs.filter(
        //   obj => obj.entity == item.id
        // );
        this.selectedEntityMeters = item.meters;
        let unPairedMeters = this.meters.filter(p=> p.locEntities.length == 0);
        // const results = this.meters.filter(({ id: value1 }) => !item.meters.some(({ id: value2 }) => value2 === value1));
        this.meters = unPairedMeters;
      }
    },
    onMeterClick(meter) {
      this.entityMeterObjs.push({
        entity: this.selectedEntity[0].id,
        meter: meter
      });
      this.selectedEntityMeters.push(meter);
      this.meters.splice(this.meters.findIndex(m => m.id == meter.id), 1);
    },
    onMeterClose(meter) {
      this.selectedEntityMeters.splice(
        this.selectedEntityMeters.findIndex(m => m.id == meter.id),
        1
      );
      this.entityMeterObjs.splice(
        this.entityMeterObjs.findIndex(m => m.meter.id == meter.id),
        1
      );
      this.meters.push(meter);
    }
  }
};
</script>
  
<style scoped>
</style>


